

























































import { Component, Emit, Vue } from "vue-property-decorator";
import { TestAnswer } from "./index";
import ObjectiveScore from "./component-objective-score.vue";

@Component({
  name: "BatchObjectiveQtySelOneDialog",
  components: {
    ObjectiveScore,
  },
})
export default class extends Vue {
  @Emit("handleConfirmed") private async handleConfirmed(
    standardAnswers: TestAnswer[]
  ) {}
  private textarea: string = "";
  private dialogVisible: boolean = false;
  private standardAnswers: TestAnswer[] = [];

  async show(pickedItems: TestAnswer[]) {
    //await this.__init();
    this.standardAnswers = pickedItems;
    this.dialogVisible = true;
  }
  private doSettingClick() {
    const rightOptions: string[] = [];
    for (let i = 0; i < this.textarea.length; i++) {
      switch (this.textarea[i].toUpperCase()) {
        case "A": {
          rightOptions.push("1|A");
          break;
        }
        case "B": {
          rightOptions.push("2|B");
          break;
        }
        case "C": {
          rightOptions.push("4|C");
          break;
        }
      }
    }
    if (rightOptions.length != this.standardAnswers.length) {
      this.$message({
        message: `选项数不对，选项数应为:${this.standardAnswers.length}`,
        type: "success",
      });
      return;
    }
    for (let i = 0; i < this.standardAnswers.length; i++) {
      this.standardAnswers[i].standardAnswer = rightOptions[i];
      this.standardAnswers[i].displayText = rightOptions[i].split("|")[1];
    }
  }
  private doOkClick() {
    this.handleConfirmed(this.standardAnswers).then(() => {
      this.dialogVisible = false;
    });
  }
}
