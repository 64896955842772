
















import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import * as _ from "lodash";

//1. 模仿朗读，使用en.pred.score
@Component({
  name: "EnPredScore",
  components: {

  }
})
export default class extends Vue {
  @Prop({ default: {} }) requestObject!: any;

  @Emit("onRefTextChanged") handleRefTextChanged(
    refText: string
  ) {}

  @Watch("requestObject", { immediate: true, deep: true })
  onRequestObjectChange(val: any, oldVal: any) {
    //alert("project");
    if (this.requestObject) {
      console.log(`requestObject watch change: ${JSON.stringify(this.requestObject)}`);
      if (!_.has(this.requestObject, "refText")) {
        /*
        _.merge(this.requestObject, {
          refText: ""
        })
        */
       this.$set(this.requestObject, "refText", "");
      }
    }
  };

  handleRefTextChange(val: string) {
    this.handleRefTextChanged(val);
  }
}
