























import { Component, Emit, Vue } from "vue-property-decorator";
@Component({
  name: "TestPaperOriginalFileDialog",
  components: {},
})
export default class extends Vue {
  private originalFileUrl: string = "";
  private dialogVisible: boolean = false;
  async show(testPaperOriginalFileUrl: string) {
    this.originalFileUrl = testPaperOriginalFileUrl;
    //await this.__init();
    this.dialogVisible = true;
  }
}
