
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as _ from "lodash";

//1. 客观题，使用quesitonType,standardAnswer
@Component({
  name: "ObjectiveScore",
  components: {},
})
export default class extends Vue {
  @Prop({ default: {} }) params!: any;
}
