




















































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as _ from "lodash";

interface TagItem {
  text: string;
}

@Component({
  name: "ScoreTags",
})
export default class extends Vue {
  @Prop({ default: () => [] }) tags!: TagItem[];
  @Prop({ default: "" }) inputType!: string;
  @Prop({ default: "n0" }) separator!: string;

  @Emit("onTagsChanged") handleTagsChanged(
    action: string,
    item: { text: string },
    tags: TagItem[]
  ) {}
  /*
    @Watch("items", { immediate: true, deep: true })
    onRequestObjectChange(val: any, oldVal: any) {
        this.tags.splice(0, )
    }
    */

  @Watch("inputValue")
  onInputValueChange(val: string, oldVal: string) {
    if (this.currentTag) {
      //修改
    } else {
      this.inputTags.splice(0, this.inputTags.length);
      this.inputTags.push(...this.splitInputValue());
    }
  }

  private inputVisible: boolean = false;
  private inputValue: string = "";
  private inputTags: string[] = [];
  private autoSplit01: boolean = false;
  
  private currentTag: TagItem | undefined = undefined;

  handleTagClose(tag: any) {
    console.log(`handleTagClose: ${JSON.stringify(tag)}`);
    const idx = this.tags.findIndex((item: TagItem) => {
      return tag.text == item.text;
    });
    console.log(`handleTagClose tag index: ${idx}`);
    if (idx >= 0) {
      this.tags.splice(idx, 1);
      this.handleTagsChanged("delete", tag, this.tags);
    }
  }

  handleTagClick(tag: any) {
    console.log(`handleTagClick: ${JSON.stringify(tag)}`);
    this.currentTag = tag;
    this.inputValue = tag.text;
    this.inputVisible = true;
  }

  private splitInputValue(): string[] {
    let arrValues: string[] = [];
    switch (this.separator) {
      case "n1": {
        arrValues = _.split(this.inputValue, "\n");
        break;
      }
      case "n2": {
        arrValues = _.split(this.inputValue, "|");
        break;
      }
      case "n3": {
        arrValues = _.split(this.inputValue, "/");
        break;
      }
      case "n4": {
        arrValues = _.split(this.inputValue, ".");
        break;
      }
      default: {
        arrValues.push(this.inputValue);
      }
    }
    let i: number = 0;
    do {
      const s: string = arrValues[i].trim();
      if (!s) {
        arrValues.splice(i, 1);
        continue;
      }
      arrValues[i] = s;
      i++;
    } while (i < arrValues.length);
    return arrValues;
  }

  handleInputValueChange(val: string) {
    return;
    if (this.currentTag) {
      //修改
    } else {
      this.inputTags.splice(0, this.inputTags.length);
      this.inputTags.push(...this.splitInputValue());
    }
  }

  handleInputTagClose(inputTag: string) {
    let idx: number = -1;
    do {
      idx = this.inputTags.findIndex((item) => item == inputTag);
      if (idx >= 0) {
        this.inputTags.splice(idx, 1);
      }
    } while (idx < 0);
  }

  doResetInputTagsClick() {
    this.inputTags.splice(0, this.inputTags.length);
    this.inputTags.push(...this.splitInputValue());
  }

  handleOkClick() {
    if (this.inputValue) {
      if (this.currentTag) {
        //修改
        this.currentTag.text = this.inputValue;
        this.handleTagsChanged("edit", this.currentTag, this.tags);
      } else {
        //增加，拆分
        let arrValues: string[] = this.inputTags;
        //
        console.log(`handleOkClick inputValue: ${this.inputValue}`);
        console.log(`handleOkClick arrValues: ${JSON.stringify(arrValues)}`);

        for (let i = 0; i < arrValues.length; i++) {
          if (i > 0 && arrValues[i] == this.inputValue) {
            //换行符拆分，不明原因为什么多一行，暂用此方法忽略
            continue;
          }
          const tag: TagItem = {
            text: arrValues[i].trim(),
          };
          if (tag.text == "") {
            //空内容跳过
            continue;
          }
          console.log(`handleOkClick: ${arrValues[i]}`);
          console.log(`handleOkClick: ${i}: ${JSON.stringify(tag)}`);
          const idx = this.tags.findIndex((item: TagItem) => {
            return tag.text == item.text;
          });
          if (idx < 0) {
            this.tags.push(tag);
            console.log(`handleOkClick push: ${JSON.stringify(tag)}`);
            this.handleTagsChanged("add", tag, this.tags);
          }
        } //for
      }

      this.inputVisible = false;
      this.inputValue = "";
      console.log(`input vaule: ${this.inputValue}`);
    }
  }

  handleDialogClose() {
    this.inputVisible = false;
  }

  showInput() {
    this.currentTag = undefined;
    this.inputValue = "";
    this.inputTags.splice(0, this.inputTags.length);
    this.inputVisible = true;
    /*
    this.$nextTick(() => {
      this.$refs.saveTagInput.$refs.input.focus();
    });
    */
  }
  /*

  handleInputConfirm() {
    let inputValue = this.inputValue;
    if (inputValue) {
      this.dynamicTags.push(inputValue);
    }
    this.inputVisible = false;
    this.inputValue = '';
  }
  */
  mounted() {
    console.log(`tags: ${JSON.stringify(this.tags)}`);
  }
}
